/**
 * @version     $Id$
 * @package     PageFly_Shared_Library
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

// Import necessary libraries.
import React from 'react'
import PropTypes from 'prop-types'
import Router from '../includes/router'

/**
 * React component to render a link.
 */
export default class Link extends React.Component {
	static propTypes = {
		href: PropTypes.string,
		title: PropTypes.string,
		target: PropTypes.string,
		rel: PropTypes.string,
		download: PropTypes.string,
		className: PropTypes.string,
		onClick: PropTypes.func
	}

	static defaultProps = {
		href: '',
		title: '',
		target: '_self',
		rel: '',
		download: false,
		className: '',
		onClick: null
	}

	constructor(props) {
		super(props)

		// Bind event handlers to correct context.
		this.click = this.click.bind(this)
	}

	click(event) {
		const {href, onClick} = this.props

		if (typeof onClick === 'function') {
			event.persist()
			onClick(event)
		} else if (href.substr(0, 1) === '/') {
			event.preventDefault()
			event.stopPropagation()
			Router.route(href)
		}
	}

	render() {
		const {href, title, target, rel, download, className} = this.props

		return (
			<a
				href={href}
				title={title}
				target={target}
				rel={rel}
				download={download}
				className={className}
				onClick={this.click}
			>
				{this.props.children}
			</a>
		)
	}
}
