/**
 * @version     $Id$
 * @package     PageFly_Shared_Library
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

// Emulate CustomEvent class on Internet Explorer.
if (navigator.appName.indexOf('Internet Explorer') >= 0) {
	if (!window.CustomEvent) {
		window.CustomEvent = (event, params) => {
			const evt = document.createEvent('CustomEvent')

			params = params || {
				bubbles: false,
				cancelable: false,
				detail: undefined
			}

			evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)

			return evt
		}

		window.CustomEvent.prototype = window.Event.prototype;
	}
}

/**
 * Method to trigger a custom event on a DOM node.
 *
 * @param   object  elm     Element to trigger a custom event on.
 * @param   string  type    Type of custom event to trigger.
 * @param   object  detail  Data to set to detail key of event object.
 *
 * @return  void
 */
export function triggerEvent(elm, type, detail) {
	const event = new CustomEvent(type, {bubbles: true, detail: detail})

	elm.dispatchEvent(event)
}
