/**
 * @version     $Id$
 * @package     PageFly_Shared_Library
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

// Import necessary libraries.
import React from 'react'
import PropTypes from 'prop-types'
import Link from './link'

/**
 * React component to render a menu.
 */
export default class Menu extends React.Component {
	static propTypes = {
		show: PropTypes.bool,
		items: PropTypes.array,
		className: PropTypes.string
	}

	static defaultProps = {
		show: true,
		items: [],
		className: ''
	}

	render() {
		// Prepare menu class.
		const classes = ['dropdown-menu']

		if (this.props.show) {
			classes.push('show')
		}

		if (this.props.className) {
			classes.push(this.props.className)
		}

		return (
			<div
				ref="mountedRoot"
				className={classes.join(' ')}
			>
				{this.props.items.map((item, idx) => {
					return item.link ? (
						<Link
							key={'link-' + idx}
							href={item.link}
							className="dropdown-item"
							onClick={item.onClick}
						>
							{item.text}
						</Link>
					) : item.onClick ? (
						<button
							key={'button-' + idx}
							type="button"
							className="dropdown-item"
							onClick={item.onClick}
						>
							{item.text}
						</button>
					) : (
						<span key={'text-' + idx} className="dropdown-item-text">{item.text}</span>
					)
				})}
			</div>
		)
	}
}
